/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { NotificationsIcon } from "assets/icons";
// components
import UserOptionsDropdown from "components/Dropdowns/UserOptionsDropdown";
import NotificationsDropdown from "components/Dropdowns/NotificationsDropdown";
import BusinessesDropdown from "components/Dropdowns/BusinessesDropdown";

export default function Navbar(props) {
  return (
    <>
      <nav className="w-full flex flex-wrap items-center justify-between px-2 py-3  navbar-expand-lg bg-dark shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div
            className="lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none"
            id="main-navbar"
          >
            <ul className="flex  gap-5 lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
                <BusinessesDropdown />
              </li>
              <li className="flex items-center">
                <UserOptionsDropdown />
              </li>

              <li className="flex items-center">
                <NotificationsDropdown />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
