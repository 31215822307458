import React, { useRef, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, format, parseISO } from "date-fns";
import { ClockIcon,ArrowDownIcon } from "assets/icons";
import "./datepicker.css"
const styles = {
  label: "text-white text-sm ltr:font-gotham-medium rtl:font-semibold",
  field:
    "border border-gray-80 text-dark focus:outline-none focus:shadow-outline py-1 w-full rounded appearance-none ",
  option: "flex gap-2 items-center",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-sm text-dark bg-error-50 focus:outline-none focus:shadow-outline focus:ring-error-900  py-2 w-full rounded appearance-none border-error-900",
};

function DatePickerField(props) {
  const {
    value,
    setValue,
    name,
    errors,
    touched,
    label,
    hideErrorMessage = false,
    borderStyle,
    ...rest
  } = props;
  const datePickerRef = useRef(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false); // Track open state
  
  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen)
    datePickerRef.current.setOpen(!isDatePickerOpen);
  };
  const CustomInput = ({ value, onClick }) => (
    <div className="relative rounded-3xl text-sm bg-gray-30 px-3 py-2 flex items-center text-white mt-3">
      <ClockIcon
        className="mr-2 cursor-pointer"
        size={18}
        onClick={() => toggleDatePicker()}
      />
      
      <span className="cursor-pointer" onClick={() => toggleDatePicker()}>
        {value}
        
      </span>
     
    </div>
  );

  const handleDateChange = (date) => {
    setValue(date);
    toggleDatePicker();
    
  };

  return (
    <div className="w-full flex flex-col pb-4">
    <label className={styles.label} htmlFor={name}>
        {label}
      </label>
    <div className={`w-full flex flex-col   `+ borderStyle}>
      
      <DatePicker
        ref={datePickerRef}
        selected={value ? new Date(value) : null}
        onChange={handleDateChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="MMMM d, HH:mm"
        customInput={<CustomInput />}
        className={touched && errors ? styles.fieldError : styles.field}
        name={name}
        selectsRange={false}
        open={isDatePickerOpen}
        {...rest}
      />
      {!hideErrorMessage && (
        <ErrorMessage component="p" className={styles.errorMsg} name={name} />
      )}
    </div>
    </div>
  );

}

export default DatePickerField;
