import React, { useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
// import IntlMessages from "components/Utils/intlMessages";

const styles = {
  label: "text-white text-sm  ltr:font-gotham-medium rtl:font-semibold",
  field:
    "border border-gray-80 text-dark focus:outline-none focus:shadow-outline py-1 w-full rounded appearance-none",
  option: "flex gap-2 items-center",
  errorMsg: "text-error-50 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-sm text-dark bg-error-50 focus:outline-none focus:shadow-outline focus:ring-error-900  py-2 w-full rounded appearance-none border-error-900",
};

function CheckBox(props) {
  const { buttonStyled, label, errors, touched, name, options, ...rest } =
    props;

  const formik = useFormikContext(); // Access the formik context

  useEffect(() => {
    if (formik.values[name] === undefined) {
      formik.setFieldValue(name, []); // Initialize the field value as an empty array if it's undefined
    }
  }, [formik.values, name]);

  return (
    <div className={`w-full flex gap-5`}>
      <div className="flex items-center justify-between">
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        {/* <span
          onClick={() => handleClearField()} // Updated the function call
          className="text-primary-500 cursor-pointer"
        >
          <IntlMessages id="global.clear" />
        </span> */}
      </div>
      <Field
        className={touched && errors ? styles.fieldError : styles.field}
        id={name}
        name={name}
      >
        {({ field }) => {
          const selectedValues = Array.isArray(field.value) ? field.value : []; // Ensure field.value is always an array
          return options?.map((option) => {
            const isChecked = selectedValues?.includes(option.id);
            return (
              <div
                className={`${styles.option} ${
                  buttonStyled ? "bg-gray-30 rounded-full px-4 py-2" : ""
                }`}
                key={option.id}
              >
                <input
                  type="checkbox"
                  id={option.id}
                  name={name}
                  value={option.id}
                  checked={isChecked}
                  onChange={() => {
                    if (isChecked) {
                      const updatedValues = selectedValues.filter(
                        (value) => value !== option.id
                      );
                      formik.setFieldValue(name, updatedValues);
                    } else {
                      const updatedValues = [...selectedValues, option.id];
                      formik.setFieldValue(name, updatedValues);
                    }
                  }}
                  className="text-primary-500 accent-primary-500 rounded focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 focus:ring-opacity-50"
                />
                <label className={styles.label + " " + "!font-gotham-regular"}>
                  {option.name}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}

export default CheckBox;
