import React from "react";
import { Field, ErrorMessage } from "formik";
import Progress from "components/Progress/Progress";
import { CheckCircleIcon } from "assets/icons";
const styles = {
  label:
    "flex items-center rounded text-gray-200 text-base ltr:font-gotham-medium rtl:font-semibold cursor-pointer p-[0.69rem] border border-dashed border-gray-200",
  field: "w-[0.1px] h-[0.1px] opacity-0 overflow-hidden ",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1 ",
  labelError:
    "text-error-500 border-error-500 flex items-center rounded text-base ltr:font-gotham-medium rtl:font-semibold cursor-pointer  p-[0.69rem] border-2 border-dashed",
};
function File(props) {
  const {
    name,
    errors,
    touched,
    label,
    icon,
    hideErrorMessage = false,
    uploaded,
    uploadPercentage,
    uploadedLabel,
    ...rest
  } = props;
  return (
    <div className="w-full flex flex-col pb-4">
      {uploaded ? (
        <p className={`${styles.label} text-primary-500`}>
          <CheckCircleIcon className="mr-2" />
          {uploadedLabel}
        </p>
      ) : uploadPercentage > 0 ? (
        <Progress percentage={uploadPercentage} color="primary-500" />
      ) : (
        <>
          <label
            className={errors && touched ? styles.labelError : styles.label}
            htmlFor={name}
          >
            {icon}
            {label}
          </label>
          <Field className={styles.field} name={name} {...rest} id={name} />

          {!hideErrorMessage && (
            <ErrorMessage
              component="p"
              className={styles.errorMsg}
              name={name}
            />
          )}
        </>
      )}
    </div>
  );
}
export default File;
