import React, { useEffect } from "react";

import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor, queryClient } from "./redux";
// import Boot from './redux/boot'
import Routes from "./router";
import AppProvider from "./AppProvider";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    // const roomName = JSON.parse(document.getElementById('room-name').textContent);

    // const chatSocket = new WebSocket(
    //   "wss://dev.metrospeedy.com/socket/851d1f3c-392c-4ad0-8cd3-68780281a08d/"
    // );

    // chatSocket.onmessage = function (e) {
    //   console.log(" - - - Message received");
    //   const data = JSON.parse(e.data);
    //   console.log(data.message);
    //   console.log(data.data);
    // };
    // chatSocket.onopen = function (e) {
    //   console.log("- - - Connected to chat socket");
    // };

    // chatSocket.onclose = function (e) {
    //   console.error("Chat socket closed unexpectedly");
    //   console.log("- - - Retrying connection...");
    // };
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppProvider>
              <Routes />
            </AppProvider>
          </PersistGate>
        </Provider>
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
}

export default App;
