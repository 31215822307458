import React, { useState, useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import IntlMessages from "components/Utils/intlMessages";
import { actions } from "redux/auth/actions";
import { get as _get } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import listenForOutsideClick from "utils/helpers/listen-for-outside-clicks";
import { Link, useParams, useLocation } from "react-router-dom";
import Divider from "components/Divider/Divider";
import {
  ChevronDownIcon,
  LogoutIcon,
  UserIcon,
  SettingsIcon,
  HomeOutlineIcon,
} from "assets/icons";
import panelActions from "redux/userPanel/actions";
const UserOptionsDropdown = ({ handleToggleProfileModal }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userPanel = useSelector((state) => state.Panel);
  const User = useSelector((state) => state.User);
  const businesses = _get(User.data, "businesses", false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  // console.log(User);
  const MAX_CHARACTERS = 20;
  const name = User.data.profile.name ?? selectedBusiness?.name;
  const truncatedName =
    name.length > MAX_CHARACTERS ? name.slice(0, MAX_CHARACTERS) + "..." : name;
  useEffect(() => {
    if (userPanel.panel === null) {
      const userPanel = localStorage.getItem("panel");
      dispatch(panelActions.switchPanel(userPanel));
    }
    const filteredBusiness = businesses.filter(
      (item) => item.id === userPanel.panel
    )[0];
    setSelectedBusiness(filteredBusiness);
    // console.log(userPanel);
    // console.log(businesses);
    // console.log(selectedBusiness);
  }, [businesses, userPanel.panel]);

  const logout = () => {
    dispatch(actions.logout());
  };
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = useRef(null);
  const popoverDropdownRef = useRef(null);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const [listening, setListening] = useState(false);
  useEffect(
    listenForOutsideClick(
      listening,
      setListening,
      btnDropdownRef,
      popoverDropdownRef,
      setDropdownPopoverShow
    )
  );
  return (
    <>
      <button
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        className="flex gap-5 items-center text-white"
      >
        <div className="flex gap-2 items-center">
          <img
            className={` w-10 h-10 object-cover rounded-lg`}
            src={
              selectedBusiness?.logo ||
              require("assets/images/avatar.png").default
            }
            alt={User.data.profile.name}
          />
          <div className="flex gap-1 flex-col items-start">
            <span className="text-sm text-white font-gotham-bold">
              {truncatedName}
            </span>
            <span className="text-xs text-primary-500 font-gotham-medium">
              {selectedBusiness?.role}
            </span>
          </div>
        </div>

        <ChevronDownIcon size={20} />
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-sm z-50 float-start p-4 flex flex-col gap-3 items-start justify-start list-none text-start rounded shadow-lg min-w-[10rem]"
        }
      >
        {/* <span
          className={
            "text-xs font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
          }
        ></span> */}
        <Link
          to={
            location.pathname?.includes("/dispatcher")
              ? "/admin/dashboard"
              : "/dispatcher"
          }
          className={`flex items-center gap-3 text-sm hover:text-primary font-gotham-medium w-full whitespace-nowrap bg-transparent text-gray-500 `}
        >
          <HomeOutlineIcon className="text-primary-500" size={16} />
          <IntlMessages
            id={
              location.pathname?.includes("/dispatcher")
                ? "global.dashboard"
                : "global.dispatcherHome"
            }
          />
        </Link>
        <Divider py="0" borderColor="border-gray-35" />
        {location.pathname?.includes("/dispatcher") ? (
          <button
            onClick={handleToggleProfileModal}
            className={`flex items-center gap-3 text-sm hover:text-primary font-gotham-medium w-full whitespace-nowrap bg-transparent text-gray-500 `}
          >
            <UserIcon className="text-primary-500" size={16} />
            <IntlMessages id="global.myProfile" />
          </button>
        ) : (
          <Link to="/admin/myprofile">
            <button
              className={`flex items-center gap-3 text-sm hover:text-primary font-gotham-medium w-full whitespace-nowrap bg-transparent text-gray-500 `}
            >
              <UserIcon className="text-primary-500" size={16} />
              <IntlMessages id="global.myProfile" />
            </button>
          </Link>
        )}

        <Divider py="0" borderColor="border-gray-35" />
        <Link to="/admin/settings">
          <button
            className={`flex items-center gap-3 text-sm hover:text-primary font-gotham-medium w-full whitespace-nowrap bg-transparent text-gray-500 `}
          >
            <SettingsIcon className="text-primary-500" size={16} />
            <IntlMessages id="global.settings" />
          </button>
        </Link>
        <Divider py="0" borderColor="border-gray-35" />
        <button
          onClick={logout}
          className={`flex items-center gap-3 text-sm hover:text-primary font-gotham-medium w-full whitespace-nowrap bg-transparent text-error-300 `}
        >
          <LogoutIcon className="text-error-300" size={16} />
          <IntlMessages id="global.logout" />
        </button>
      </div>
    </>
  );
};

export default UserOptionsDropdown;
