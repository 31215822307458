import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import DashboardNavbar from "components/Navbars/DashboardNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Footer from "components/Footers/Footer.js";

export default function DashboardLayout({ children }) {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-10 min-h-screen pb-4">
        {/* Header */}
        <DashboardNavbar />
        <div className="rounded-3xl">{children}</div>
      </div>
    </>
  );
}
