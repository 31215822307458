import { actionTypes } from "./actions";

const initState = {
  isLoading: false,
  isSuccess: false,
  successMessage: null,
  isFailed: false,
  error: null,
};

const loginRequestSuccess = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const profileRequest = (state, payload) => ({
  ...state,
  isLoading: true,
});

const profileRequestSucces = (state, payload) => ({
  ...state,
  user: {
    ...payload.data,
  },
  isLoading: false,
  isSuccess: true,
  successMessage: payload.message,
  isFailed: false,
  error: null,
});
const profileRequestFailed = (state, payload) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  successMessage: null,
  isFailed: true,
  error: payload,
});

// const updateUserReducerData = (state, payload) => ({
//   ...state,
//   data: {
//     ...state.data,
//     profile: {
//       ...state.data.profile,
//       name: payload.business_name,
//       address: payload.business_address,
//       email: payload.business_email,
//       phone: payload.business_phone,
//     },
//   },
//   isLoading: false,
//   isSuccess: true,
//   successMessage: null,
//   isFailed: false,
//   error: null,
// });

const updateUserReducerData = (state, payload) => ({
  ...state,
  data: {
    ...state.data,
    businesses: state.data.businesses.map((business) => {
      return business.id === payload.panel
        ? {
            ...business,
            business_name: payload.business_name,
            business_address: payload.business_address,
            business_phone: payload.business_phone,
            business_email: payload.business_email,
          }
        : business;
    }),
  },
  isLoading: false,
  isSuccess: true,
  successMessage: null,
  isFailed: false,
  error: null,
});
export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actionTypes.LOGIN_REQUEST_SUCCESS:
      // console.log(payload)
      return loginRequestSuccess(state, payload);
    case actionTypes.PROFILE_REQUEST:
      return profileRequest(state, payload);
    case actionTypes.PROFILE_REQUEST_SUCCESS:
      return profileRequestSucces(state, payload);
    case actionTypes.PROFILE_REQUEST_FAILED:
      return profileRequestFailed(state, payload);
    case actionTypes.UPDATE_USER_REDUCER_DATA:
      return updateUserReducerData(state, payload);
    default:
      return state;
  }
}
