/*eslint-disable*/
import React, { useState,  useEffect, useRef} from "react";
import { Link } from "react-router-dom";
// components
import UserOptionsDropdown from "components/Dropdowns/UserOptionsDropdown";
import NotificationsDropdown from "components/Dropdowns/NotificationsDropdown";
import { PersonOutlineIcon, MenuIcon } from "assets/icons";
import ProfileModal from "components/Modals/ProfileModal";
import Divider from "components/Divider/Divider";
import Button from "components/Buttons/Button";
import { ChevronRightIcon } from "assets/icons";
import { ChevronLeftIcon } from "assets/icons";
import { Form, Formik, Field } from "formik";
import FormikController from "components/Forms/FormikController";
import BusinessesDropdown from "components/Dropdowns/BusinessesDropdown";
import { ResetPassSchema } from "components/validation/ResetPassSchema";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actions } from "redux/auth/actions";
import ToggleButtons from "components/Buttons/ToggleButtons";
export default function Navbar({ view, setView, onNavChange }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isShowProfileModal, setIsShowProfileModal] = useState(false);
  const [isShowChangePass, setIsShowChangePass] = useState(false);
  const [height, setHeight] = useState(0);
  const initialResetValues = {
    // old_password: "",
    password: "",
    password_confirmation: "",
    logout_all_devices: false,
  };
  const handleToggleProfileModal = () =>
    setIsShowProfileModal(!isShowProfileModal);

  const handleToggleChangePass = () => setIsShowChangePass(!isShowChangePass);

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const { ...data } = values;
    dispatch(actions.changePasswordRequest(data));
    handleToggleProfileModal();
  };
  const user = useSelector((state) => state.User || {}, shallowEqual);
  const NavRef = useRef();
  useEffect(() => {
    if (!NavRef?.current?.clientHeight) {
      return;
    }
    setHeight(NavRef?.current?.clientHeight);
    onNavChange(height)

  }, [NavRef?.current?.clientHeight]);
  return (
    <>
      <div className=" ">
        <nav
          ref={NavRef}
          className="relative w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-height navbar-expand-lg bg-dark  shadow"
        >
          <div className="container w-full px-4 mx-auto flex flex-wrap gap-5 items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <Link
                to="/"
                className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              >
                <img
                  src={require("assets/images/metrospeedy-logo.png").default}
                  alt="Metrospeedy"
                  className="w-52"
                />
              </Link>
              <button
                className="cursor-pointer text-xl text-white leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <MenuIcon />
              </button>
            </div>

            <div
              className={
                "lg:flex items-center lg:shadow-none " +
                (navbarOpen ? " block" : " hidden")
              }
              id="main-navbar"
            >
              <div className="flex items-center justify-center">
                {" "}
                <ToggleButtons view={view} setView={setView} />
              </div>{" "}
              <ul className="flex justify-center w-full lg:w-auto gap-5 items-center list-none lg:ml-4">
                <li className="flex items-center">
                  <BusinessesDropdown />
                </li>
                <li className="flex items-center flex-shrink-0">
                  <UserOptionsDropdown
                    handleToggleProfileModal={handleToggleProfileModal}
                  />
                </li>

                <li className="flex items-center">
                  <NotificationsDropdown />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {isShowProfileModal && (
        <ProfileModal
          title={
            <h5 className="text-base font-gotham-bold flex items-center text-gray-500">
              {!isShowChangePass ? (
                <>
                  <PersonOutlineIcon
                    size={30}
                    className="text-primary-500 mr-3 bg-primary-100 rounded-xl p-2"
                  />
                  Profile
                </>
              ) : (
                <>
                  <ChevronLeftIcon
                    size={25}
                    className="mr-2 text-primary-50 cursor-pointer"
                    onClick={handleToggleChangePass}
                  />
                  Change Password
                </>
              )}
            </h5>
          }
          handleCloseModal={handleToggleProfileModal}
          actionsPannel={false}
          size={"w-full lg:w-1/4 md:w-1/2"}
          color={"primary"}
          bg={"white"}
        >
          {!isShowChangePass ? (
            <>
              <Divider borderColor="border-gray-15" py="0" />
              <article className="py-5">
                <h6 className="text-gray-200 text-xs mb-1">Name</h6>
                <p className="text-gray-500 text-sm font-gotham-medium">
                  {user.data.businesses[0].name}
                </p>
              </article>
              <Divider borderColor="border-gray-15" py="0" />
              <article className="py-5">
                <h6 className="text-gray-200 text-xs mb-1">Email</h6>
                <p className="text-gray-500 text-sm font-gotham-medium">
                  {user.data.profile.email}
                </p>
              </article>
              <Divider borderColor="border-gray-15" py="0" />
              <article className="py-5">
                <h6 className="text-gray-200 text-xs mb-1">Phone Number</h6>
                <p className="text-gray-500 text-sm font-gotham-medium">
                  {user.data.profile.phone}
                </p>
              </article>
              <Divider borderColor="border-gray-15" py="0" />
              <article className="py-5">
                <h6 className="text-gray-200 text-xs mb-1">Password</h6>
                <Button
                  onClick={handleToggleChangePass}
                  variant="text"
                  size="auto"
                  className="text-sm"
                >
                  change password <ChevronRightIcon />
                </Button>
              </article>
            </>
          ) : (
            <>
              <Divider borderColor="border-gray-15" py="0" />
              <Formik
                initialValues={initialResetValues}
                validationSchema={ResetPassSchema}
                onSubmit={onSubmit}
                render={({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form className="py-3">
                    {/* <div className="my-3">
                      <FormikController
                        control="input"
                        errors={errors.old_password}
                        touched={touched.old_password}
                        type="password"
                        label="Old Password"
                        name="old_password"
                        placeholder="Enter old password"
                      />
                    </div> */}
                    <div className="my-3">
                      <FormikController
                        control="input"
                        errors={errors.password}
                        touched={touched.password}
                        type="password"
                        label="New Password"
                        name="password"
                        placeholder="Enter new password"
                      />
                    </div>

                    <div className="mt-3">
                      <FormikController
                        control="input"
                        errors={errors.password_confirmation}
                        touched={touched.password_confirmation}
                        type="password"
                        label="New Password Confirmation"
                        name="password_confirmation"
                        placeholder="Re-enter new password"
                      />
                    </div>

                    <Field
                      type="checkbox"
                      name="logout_all_devices"
                      className="text-primary-500 accent-primary-500 rounded focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 focus:ring-opacity-50"
                    />
                    <label
                      htmlFor="logout_all_devices"
                      className="form-check-label text-sm leading-3  text-center font-inter-regular text-dark ml-3"
                    >
                      logout from all devices
                    </label>
                    <Button type="submit" size="large" className="w-full mt-3">
                      Reset Password
                    </Button>
                  </Form>
                )}
              />
            </>
          )}
        </ProfileModal>
      )}
    </>
  );
}
