import React from "react";

const AuthLayout = ({ children }) => {
  const bgImage = require("../assets/images/auth-bg.png").default;
  const backgroundStyles = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div
      className="mx-auto min-h-screen flex flex-col items-center justify-center text-center text-sm sm:text-base bg-black"
      style={backgroundStyles}
    >
      <div className="w-[90%] lg:w-2/5 md:w-1/2 ">{children}</div>
    </div>
  );
};

export default AuthLayout;
