import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DispatcherNavbar from "components/Navbars/DispatcherNavbar";
import DispatcherFooter from "components/Footers/DispatcherFooter";
import { MapMarkerIcon } from "assets/icons";
import {
  Map,
  Marker,
  GoogleApiWrapper,
  Polyline,
  InfoWindow,
} from "google-maps-react";
const DispatcherLayout = ({
  children,
  google,
  mapLocations,
  view,
  driversData,

  tasksData,
  setView,
  selectNavHeight,
}) => {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const [map, setMap] = useState({
    lat: 40.494978, // Replace with your desired default latitude
    lng: -74.479741, // Replace with your desired default longitude
  });
  // const ZOOM_LEVEL = 10; // Replace with your desired zoom level
  const [zoomLevel, setZoomLevel] = useState(10);
  const [center, setCenter] = useState({});
  const [navHeight, setNavHeight] = useState(0);
  const [driversLocations, setDriversLocations] = useState([]);
  useEffect(() => {
    if (driversData) {
      const locations = driversData?.pages
        .map((page) => page.data)
        .map((res) => [...res]);
      setDriversLocations(() => locations);
    }
  }, [driversData]);
  useEffect(() => {
    if (mapLocations && mapLocations.length > 0) {
      setCenter({
        lat: mapLocations[0]?.latitude,
        lng: mapLocations[0]?.longitude,
      });
      setZoomLevel(14);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMap({ lat: latitude, lng: longitude });
        },
        () => {
          // Handle error when geolocation is not available
          console.error("Error getting current position.");
        }
      );
    } else {
      // Handle case when geolocation is not supported
      console.error("Geolocation is not supported.");
    }
  }, []);

  useEffect(() => {
    if (mapLocations && mapLocations.length > 0) {
      setCenter({
        lat: mapLocations[0]?.latitude,
        lng: mapLocations[0]?.longitude,
      });
      setZoomLevel(14);
    }
  }, [mapLocations]);

  const statusColors = {
    unassigned: "#BBBEBF",
    pending: "#FFFF00",
    assigned: "#aa81f3",
    added_to_route: "#ffffff",
    in_progress: "#33b1f8",
    arrived: "#00b050",
    completed: "#00b050",
    cancelled: "#FF5A4D",
    failed: "#FF5A4D",
    rejected: "#FF5A4D",
    online: "#00B050",
    idel: "#FAC515",
    stale: "#E62E05",
    stuck: "#E62E05",
  };
  const handelNavHeight = (e) => {
    setNavHeight(e);
    selectNavHeight(e);
  };
  const [selectedTask, setSelectedTask] = useState();
  const [selectedMarker, setSelectedMarker] = useState();
  const taskRefRoute = useRef(new Array());
  const DriverRef = useRef(null);

  const onMarkerClickRoute = (task, index) => {
    setSelectedMarker(() => taskRefRoute.current[index].marker);
    setSelectedTask(() => task);
  };
  const lineSymbol = {
    path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
  };

  return (
    <div style={{ height: "100vh", overflow: "hidden", background: "#272727" }}>
      {view === "map" ? (
        <Map
          google={google}
          zoom={zoomLevel}
          initialCenter={map}
          center={center}
          style={{ width: "100%", height: "100%" }}
          //controlSize={10}  # not working
          zoomControl={true}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          panControl={true}
          rotateControl={false}
          fullscreenControl={false}
          zoomControlOptions={{
            position: google.maps.ControlPosition.LEFT_TOP,
          }}
          fullscreenControlOptions={{
            position: google.maps.ControlPosition.TOP_LEFT,
          }}
          // Custom map styles generated on https://mapstyle.withgoogle.com
          styles={[
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#333A40",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "labels",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#919191",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#ffffff",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text",
              stylers: [
                {
                  color: "#919191",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text",
              stylers: [
                {
                  color: "#ffffff",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "administrative.neighborhood",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ff6a00",
                },
                {
                  visibility: "simplified",
                },
                {
                  weight: 8,
                },
              ],
            },
            {
              featureType: "administrative.neighborhood",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#ff6a00",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.neighborhood",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#ff6a00",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.neighborhood",
              elementType: "labels",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "administrative.province",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.province",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#ffffff",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.province",
              elementType: "labels",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.province",
              elementType: "labels.text",
              stylers: [
                {
                  color: "#ffffff",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.province",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "landscape",
              elementType: "geometry",
              stylers: [
                {
                  color: "#333A40",
                },
              ],
            },
            {
              featureType: "landscape",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#151e26",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#a4a5a6",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#151e26",
                },
              ],
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#070a0d",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ]}
        >
          {/* Place markers on the map based on the mapLocations array */}

          {driversLocations
            ? driversLocations.map((location, index) => (
                <>
                  <Marker
                    key={index}
                    position={{
                      lat: location.last_latitude,
                      lng: location.longitude,
                    }}
                    icon={{
                      path: "M9 17.3c2-2.4 5.5-7.9 5.5-10.9 0-3.4-2.9-6-6-6s-6 2.6-6 6c0 3 3.6 8.5 5.5 10.9.3.3.7.3 1 0z",
                      fillColor: statusColors[location.status],
                      fillOpacity: 1.0,
                      strokeColor: statusColors[location.status],
                      strokeWeight: 0,
                      scale: 1.0,
                    }}
                  />
                </>
              ))
            : null}

          {/* Draw lines between the pins */}
          {mapLocations
            ? mapLocations.map((location, index) => (
                <Marker
                  onClick={() => onMarkerClickRoute(location, index)}
                  key={index}
                  ref={(element) => (taskRefRoute.current[index] = element)}
                  position={{
                    lat: location.latitude,
                    lng: location.longitude,
                  }}
                  icon={{
                    path: "M0 -1.3c2-2.4 5.5-7.9 5.5-10.9 0-3.4-2.9-6-6-6s-6 2.6-6 6c0 3 3.6 8.5 5.5 10.9.3.3.7.3 1 0z",
                    fillColor: statusColors[location.status],
                    fillOpacity: 1.0,
                    strokeColor: statusColors[location.status],
                    strokeWeight: 0,
                    scale: 1.3,
                  }}
                />
              ))
            : null}
          {mapLocations.length > 1 && (
            <Polyline
              path={mapLocations.map((location) => ({
                lat: location.latitude,
                lng: location.longitude,
              }))}
              icons={Array.from(Array(mapLocations.length * 3).keys()).map(
                (index) => ({
                  icon: lineSymbol,
                  offset: index == mapLocations.length? "100%":
                    (index /
                      Array.from(Array(mapLocations.length).keys()).length) *
                      100 +
                    "%",
                })
              )}
              strokeColor="white" // Replace with your desired color for the line
              strokeOpacity={0.8} // Replace with your desired opacity for the line (0 to 1)
              strokeWeight={2} // Replace with your desired weight for the line
            />
          )}

          <InfoWindow marker={selectedMarker} visible={true}>
            <div>
              <table className="custom-map-table ">
                <thead>
                  <tr>
                    <th>Destination</th>
                    <th>Recipient</th>
                    <th>Driver</th>
                    <th>Date/Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {selectedTask?.task?.address?.building?.street_field1}
                    </td>
                    <td>{selectedTask?.task?.executor?.name}</td>
                    <td>
                      {selectedTask?.task?.driver
                        ? selectedTask?.task?.driver
                        : "unassigned"}
                    </td>
                    <td>
                      After{" "}
                      {new Date(
                        selectedTask?.task?.time_window_start
                      ).toLocaleDateString()}{" "}
                      {new Date(selectedTask?.task?.time_window_start)
                        .toLocaleTimeString()
                        .split(" ")[0]
                        .slice(0, 4)}{" "}
                      {
                        new Date(selectedTask?.task?.time_window_start)
                          .toLocaleTimeString()
                          .split(" ")[1]
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </InfoWindow>
        </Map>
      ) : null}

      <DispatcherNavbar
        locale={locale}
        onNavChange={handelNavHeight}
        view={view}
        setView={setView}
      />

      {children}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY, // Replace with your own Google Maps API key
})(DispatcherLayout);
