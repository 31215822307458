import HandleAxiosError from 'utils/helpers/HandleAxiosError'
import ApiClient from 'utils/plugins/ApiClient'
import { toast } from "react-toastify";
export const userProfile = (payload) =>
  ApiClient.get('/user/profile', payload)
    .then((respose) => respose.data.response.data)
    .catch((error) => {
      toast.error(error.response.data.message);
        const errorList = HandleAxiosError(error)
        for (let i=0; i< errorList.length; i++) {
          toast.error(errorList[i].key + " " + errorList[i].message)
        }
    })
